.dashboardContainer {
  width: 100%;
  padding: 2rem;
  max-height: 5rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboardContainer .logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
}
.dashboardContainer .logoContainer a {
  z-index: 5;
}

.landing {
  position: absolute;
  z-index: 1;
}
.landing .logoContainer {
  margin-top: 0;
}

.container {
  position: fixed;
  box-sizing: border-box;
  right: 0;
  top: 2rem;
  margin-right: 2rem;
  left: 12rem;
  padding: 1rem 5%;
  z-index: 5;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  backdrop-filter: none;
}
.container h2 {
  font-size: 1.35rem;
  color: #1B335E;
  line-height: 0;
}

.nonLogged {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.nonLogged li {
  transition: 0.2s;
  font-size: 0.95rem;
}
.nonLogged li a {
  font-weight: 600;
  margin-right: 1rem;
  padding: 0.25rem 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #1B335E;
  transition: 0.2s;
}
.nonLogged li:last-child a {
  border: 1px solid #244EFF;
  color: #244EFF;
}
.nonLogged li:last-child a:hover {
  border-color: #244EFF;
  background: #244EFF;
  color: #fff;
}
.nonLogged li:hover {
  cursor: pointer;
}
.nonLogged li:hover a {
  color: #244EFF;
}

.notif {
  position: relative;
}
.notif::before {
  content: "2";
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 15px;
  height: 15px;
  text-align: center;
  background-color: #244EFF;
  color: #fff;
  padding: 2px;
  font-size: 0.8rem;
  right: -9px;
  top: -9px;
  border-radius: 20px;
  border: 1px solid #244EFF;
}

.pageTitle {
  background: #f5eeef;
  border-radius: 5px 10px 0 0;
  padding: 0.5rem 2rem 1rem;
  margin-left: 3.25rem;
  margin-top: 6rem;
  z-index: 2;
  font-size: 1.2rem;
}

.btnsContainer {
  margin-left: auto;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  border-right: 1px solid #1B335E;
}
.btnsContainer .studio {
  border: 1px solid #244EFF;
  color: #244EFF;
}
.btnsContainer .studio:hover {
  color: #fff;
  background: #244EFF;
}
.btnsContainer .store {
  border: 1px solid #244EFF;
  color: #244EFF;
}
.btnsContainer .store:hover {
  color: #fff;
  background: #244EFF;
  border-color: #244EFF;
}
.btnsContainer a {
  font-weight: 600;
  margin-right: 1rem;
  padding: 0.25rem 1rem;
  border: 1px solid #1B335E;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #1B335E;
  transition: 0.2s;
}
.btnsContainer a:hover {
  color: #244EFF;
  border-color: #244EFF;
}

.searchContainer {
  display: flex;
  width: 100%;
  max-width: 30rem;
  position: relative;
}
.searchContainer input {
  border: none;
  background: #fff;
  outline: none;
  border-radius: 25px;
  padding: 1rem;
  text-indent: 2rem;
  width: 100%;
}
.searchContainer svg {
  top: 0;
  bottom: 0;
  margin: auto;
  left: 1rem;
  position: absolute;
}

.headerSpan {
  color: #244EFF;
}
.headerSpan a {
  color: #244EFF;
  text-decoration: underline;
}

.add {
  cursor: pointer;
  text-align: center;
  width: 25px;
  line-height: 25px;
  height: 25px;
  color: #fff;
  font-size: 26px;
  border-radius: 20px;
  margin: 0;
  background: #244EFF;
}

.options {
  list-style: none;
  position: fixed;
  right: 1rem;
  top: 2.5rem;
  background: rgb(255, 255, 255);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  box-shadow: 0px 12px 40px rgba(230, 208, 201, 0.8);
  display: flex;
  flex-direction: column;
}
.options li {
  font-size: 1rem;
  color: #1B335E;
  font-family: "Trebuchet MS";
  padding: 0.5rem 0;
}
.options li a {
  color: #1B335E;
}
.options li:not(:last-of-type) {
  border-bottom: 1px solid #1B335E;
}

.rightSide {
  position: relative;
  display: flex;
  align-items: center;
}

.nameSize {
  opacity: 0;
  margin-right: 5.5rem;
}

.name {
  display: flex;
  align-items: center;
  position: relative;
}
.name ul {
  z-index: 3;
  background: #f7f8fb;
  padding: 0 1rem;
  border: 1px solid #1B335E;
  border-radius: 15px;
  list-style: none;
  max-height: 1.7rem;
  overflow: hidden;
  margin-right: 1rem;
  position: absolute;
  top: -99%;
  transition: 200ms max-height;
}
.name ul li:first-child {
  text-align: center;
}
.name ul li:first-child,
.name ul li:last-child {
  margin-bottom: 0.5rem;
}
.name ul:hover {
  max-height: 10rem;
  box-shadow: 0 15px 24px rgba(61, 43, 40, 0.1490196078);
}
.name > svg:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(74%) sepia(39%) saturate(5602%) hue-rotate(324deg) brightness(100%) contrast(104%);
}
.name ul > li > a {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 500;
  color: #1B335E;
  transition: 0.2s;
  margin-bottom: 1rem;
}
.name .UserName {
  display: flex;
  color: #1B335E;
  padding-top: 3px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.name .UserName > svg {
  font-size: 0.7rem;
}
.name .UserName span {
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
  font-size: 0.9rem;
  color: #1B335E;
  transition: 0.2s;
  margin-right: 0.5rem;
}

.logout {
  color: #5c74b2;
}

.reportContainer {
  display: flex;
  flex-direction: column;
  height: 3rem;
  text-align: center;
  width: 7.5rem;
  position: relative;
}
.reportContainer > div {
  transition: background-color 0.2s, border-color 0.2s;
  color: #1B335E;
  background: #ECEFF6;
  border: 1px solid #ECEFF6;
  font-weight: bold;
  font-size: 0.9rem;
  width: 100%;
  margin: 0;
  margin-top: 1.25rem;
  border-radius: 5px;
  padding: 0.5rem 0.55rem;
  display: flex;
  flex-direction: column;
}
.reportContainer > div:hover {
  border-color: #1B335E;
  color: #fff;
  background-color: #1B335E;
}
.reportContainer > div:hover button {
  color: #fff;
}
.reportContainer button {
  color: #1B335E;
  font-size: 0.6rem;
  margin-top: 0.15rem;
  cursor: pointer;
  border: none;
  background: none;
}

@keyframes menuSlide {
  0%, 80% {
    top: 0;
    max-height: 1.8rem;
  }
  99% {
    max-height: 5.4rem;
  }
  100% {
    max-height: 6rem;
    top: 29px;
  }
}
@keyframes menuSlideOut {
  0% {
    max-height: 6rem;
    top: 29px;
  }
  1% {
    max-height: 5.4rem;
    top: 29px;
  }
  100% {
    top: 0;
    max-height: 1.75rem;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 680px) {
  .dashboardContainer {
    width: 90%;
    padding-top: 1rem;
  }
  .container {
    top: 0;
    box-shadow: 0px 12px 40px rgba(230, 208, 201, 0.5);
    right: 0;
    left: 0;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
  }
}
