.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  height: 2.5rem !important;
  border-radius: 5px;
  z-index: 10;
  top: 1rem;
  position: fixed;
  color: #1B335E;
  font-weight: bold;
  max-width: 50%;
}
.container svg {
  border-radius: 20px;
  padding: 4px;
  fill: #fff;
  font-size: 1.25rem;
}
.container p {
  font-size: 1rem;
  margin: 0;
  margin-right: 0.5rem;
}

.signup {
  top: 7rem;
  right: auto;
  left: 58.5%;
}

.login {
  position: absolute;
}

@keyframes fadeInLeft {
  0% {
    top: 0.5rem;
    opacity: 0;
  }
  10% {
    top: 3rem;
    opacity: 1;
  }
  80% {
    top: 3rem;
    opacity: 1;
  }
}
.fadeOut {
  animation: fadeOutAnimate 1s forwards ease-out !important;
}

@keyframes fadeOutAnimate {
  0% {
    top: 1rem;
    opacity: 1;
  }
  100% {
    top: 0rem;
    opacity: 0;
  }
}
.valid svg {
  background: green;
}

.invalid {
  animation: shakeX 1s forwards ease-in-out;
}
.invalid svg {
  background: red;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}
