.active {
  position: relative;
}
.active * {
  color: #244EFF !important;
}
.active label {
  color: #244EFF !important;
  display: block !important;
}

.tabMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  list-style: none;
  gap: 1.35rem;
  align-items: center;
  width: 100%;
  padding: 0;
}
.tabMenu li {
  position: relative;
  font-size: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tabMenu li svg {
  width: 2rem !important;
  height: 2rem !important;
}
.tabMenu li * {
  color: #1B335E;
}
.tabMenu li > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}
.tabMenu li label {
  font-size: 0.7rem;
  text-align: center;
  font-weight: bold;
  bottom: -0.25rem;
  left: 0;
  right: 0;
  margin: auto;
  text-transform: uppercase;
}

.event {
  display: flex;
  position: relative;
}
.event :hover span {
  color: #0F1E4C;
}
.event svg {
  font-size: 1rem;
}
.event span {
  font-weight: 600;
  color: #0F1E4C;
  text-align: center;
  right: 0;
  left: 0;
  bottom: 0.5rem;
  margin: auto;
  font-size: 0.7rem;
  position: absolute;
}

@media screen and (max-width: 680px) {
  .active::after {
    bottom: -5px;
  }
  .tabMenu {
    position: fixed;
    flex-direction: row;
    z-index: 2;
    background: #fff;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 2rem;
    padding-top: 1rem;
    border-radius: 5px 5px 0 0;
  }
  .tabMenu li {
    font-size: 0.8rem;
  }
}
