.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.container h2 {
  margin-bottom: 2.5rem;
}
.container > div {
  margin-bottom: 7vh;
}
