$color-primary: #244EFF;
$color-primary-variant: #5c74b2;
$color-secondary: #FFBE00;
$color-thirdary: #e79388;
$color-dark-accent: #1B335E;
$color-darker-accent: #0F1E4C;
$color-lighter-accent: #F4F7FC;
$background-color: #efeef4;
$outter-background-color: #f7f8fb;
$backdrop-color: #d8dce3;
$color-light-accent: #E7EEF8;
$color-light-accent-variation: #DDE5F1;
$color-light-accent-variation1: #ECEFF6;
$color-light-accent-variation2: #F3F7FB;
$color-lighter-accent-variation: #F9F5F4;
$pureLight: #FFFFFF;
$pureDark: #000000;

$smallBorderRadius: 5px;
$borderRadius: 5px;
$mediumBorderRadius: 10px;
$largeBorderRadius: 10px;
$hugeRadius: 20px;

$leftColHeight: 65%;
$leftColMinHeight: 50rem;
$leftColMaxHeight: 50rem;
$leftColWidth: 65%;
$leftColMinWidth: 40rem;
$leftColMaxWidth: 40rem;