.container {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    display: flex;
    flex-direction: row-reverse;
}
.container button:first-child {
    margin-left: 1rem;
}
