.container {
  width: 100%;
  position: relative;
}

.inputOptions {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  width: 3.5rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.select {
  font-family: "Montserrat";
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: none;
  outline: none;
  background: #fff;
  border: 1px solid #244EFF;
  border-radius: 5px;
  padding: 1rem;
  width: 50%;
  display: block;
  margin: 2rem auto;
}
.select::placeholder {
  color: #1B335E;
}
.select select:focus {
  box-shadow: none;
}

.colorPreviewContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.colorPreview {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.356);
  border-radius: 20px;
  margin: 0.2rem;
  width: 20px;
  height: 20px;
}

.span {
  font-size: 0.9rem;
  text-align: left;
  margin-right: auto;
  margin-bottom: 0.5rem;
}

.or {
  margin-left: auto;
  line-height: 1;
}

.optionsContainer {
  background: #fff;
  border-radius: 0 0 5px 5px;
}

.optionContainer {
  position: absolute;
  display: flex;
  width: 100%;
  top: -1rem;
  left: 0%;
  right: auto;
  z-index: 2;
}
.optionContainer form {
  width: 100%;
}
.optionContainer button {
  border: none;
  cursor: pointer;
  background: none;
  outline: none;
}

.container input {
  transition: 0.2s;
  background: #fff;
  border: 1px solid #f6f6f6;
  padding: 1rem 5rem 1rem 1rem !important;
  text-overflow: ellipsis;
  border-radius: 5px;
  margin: 0.5rem 0;
  font-family: "Montserrat";
  outline: none;
}
.container input:focus {
  border: 1px solid #244EFF;
  box-shadow: 0px 12px 40px rgba(230, 208, 201, 0.28);
}

.colorContainer {
  flex-direction: column-reverse;
  width: 80%;
  min-width: 10vw;
  padding: 0.5rem;
  height: 12rem;
  background: #fff;
  box-shadow: 0px 12px 40px rgba(230, 208, 201, 0.8);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: -3rem;
  margin: auto;
}
.colorContainer div[aria-label=Hue] > div {
  border-radius: 0px !important;
  width: 10px;
  opacity: 1;
  border: 1px solid rgb(255, 255, 255);
  height: 26px;
}
.colorContainer input {
  width: 100%;
  padding: 0.5rem;
}

.colorPreview {
  border-radius: 20px;
  width: 20px;
  height: 20px;
}

.colorPick {
  width: 100% !important;
  margin: 0.5rem auto;
}

.inputColorSize {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.inputColorSize {
  position: relative;
}

.opened svg {
  color: #244EFF !important;
}

.textStyle {
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 2rem;
  width: 70%;
  margin: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(177, 176, 175, 0.8);
}
.textStyle li {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  font-size: 0.8rem;
  height: 100%;
}
.textStyle li:nth-child(2) {
  border-left: 1px solid;
  border-right: 1px solid;
  border-image: linear-gradient(to top, #fff 30%, rgba(61, 43, 40, 0.0823529412) 30%, rgba(61, 43, 40, 0.0823529412) 70%, #fff 70%) 1;
}
.textStyle li .inputOptions svg {
  color: #1B335E;
}
.textStyle li:hover span {
  color: #244EFF;
}
.textStyle li:last-child {
  max-width: 2rem;
}
.textStyle li:last-child button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0;
  border-radius: 20px;
  width: 1rem;
  height: 1rem;
  font-size: 0.65rem;
  margin: 0;
  background-color: #244EFF;
}
.textStyle li span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  box-sizing: border-box;
}
.textStyle li input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.textStyle li input:checked ~ span {
  color: #244EFF;
}
