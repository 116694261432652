@media screen and (max-width: 680px) {
  .container {
    padding-bottom: 10rem;
    min-height: 100vh;
    padding-top: 2rem;
    position: relative;
    margin: auto;
  }
}
.container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.container h1 {
  flex: 100%;
  margin-left: 0.5rem;
  color: #1B335E;
  font-size: 1.75rem;
}

.tilesList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 48%;
  height: 9rem;
  flex: 48%;
  max-width: 48%;
  padding: 1rem 1rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  background: #fff;
  box-shadow: 0 20px 20px rgba(206, 142, 124, 0.15);
}
.tile * {
  font-family: "Montserrat", serif;
  color: #1B335E;
  margin: 0;
  padding: 0;
}
.tile:first-of-type {
  background-color: #244EFF;
}
.tile:first-of-type span,
.tile:first-of-type p,
.tile:first-of-type svg,
.tile:first-of-type svg path {
  color: #fff !important;
}
.tile:nth-of-type(2) {
  background-color: #FFBE00;
}
.tile:nth-of-type(2) span,
.tile:nth-of-type(2) p {
  color: #fff;
}
.tile p,
.tile span {
  font-size: 1rem;
  font-weight: 400;
}
.tile p {
  line-height: 2.5;
}

.primaryTxt {
  color: #244EFF;
}

.loadingBtn {
  animation: spin infinite 1s linear;
  font-size: 1rem;
  position: absolute;
  margin: auto !important;
  inset: 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gmail {
  cursor: pointer;
}
.gmail .connected svg * {
  color: #00ca4e;
}
.gmail .notConnected svg * {
  color: #ff7954;
}
.gmail svg {
  animation: flash infinite ease 2s;
}
.gmail .onHover {
  display: none;
}
.gmail .onHover svg {
  animation: none;
}
.gmail:hover > .onHover {
  display: flex;
}
.gmail:hover > .onUnHover {
  display: none;
}

.deploy {
  cursor: pointer;
  background-color: #e79388 !important;
}
.deploy .onHover {
  display: none;
}
.deploy .onHover svg {
  animation: flash infinite ease 2s;
}
.deploy:hover > .onHover {
  display: flex;
}
.deploy:hover > .onUnHover {
  display: none;
}
.deploy * {
  color: #ffffff !important;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
.syncTile {
  perspective: 1000px;
  position: relative;
  height: 6rem;
  transition: transform 0.1s;
  flex: 100%;
  width: 100%;
}
.syncTile h4 {
  font-size: 1.2rem;
  line-height: 2rem;
  vertical-align: middle;
}

.rotating {
  animation: spin 4s linear infinite;
}

.syncContainer {
  position: relative;
}

.syncBtn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.syncTileInner {
  position: relative;
  width: 100%;
  min-height: 7rem;
  height: 100%;
  text-align: center;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.fliping {
  transform: rotateY(180deg);
}

.syncFront,
.syncBack {
  position: absolute;
  width: 100%;
  padding: 1rem;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.syncBack {
  background: red;
  transform: rotateY(180deg);
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.billingTile {
  background: #1B335E !important;
}
.billingTile * {
  color: #fff;
}

.bigTxt {
  line-height: 1.5;
  font-size: 2.5rem !important;
  font-weight: 600 !important;
}

.free {
  line-height: 3rem;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}

.row {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 40%;
  align-items: baseline;
  justify-content: space-between;
}

.blackImg {
  filter: contrast(0);
}

.watermark {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  line-height: 28px;
  color: rgba(101, 68, 62, 0.03);
}

.syncContainer {
  padding: 2rem 0 0 0;
}

.activeSpan {
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 0.8rem !important;
}

.fliping {
  transform: rotateY(180deg);
}
