.container {
  display: flex;
  flex-wrap: wrap;
  height: 90%;
  max-height: 100%;
}
.container h1 {
  width: 100%;
}

.infosContainer {
  background-color: #fff;
}

.tagline {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  margin-bottom: 0.75rem;
}
.tagline * {
  margin: 0 0 !important;
}

.overflow {
  max-width: 50%;
  width: 100%;
  min-height: 100%;
  height: 100%;
}
.overflow .signaturePreview {
  width: auto;
  height: 100%;
}

.teamsContainer {
  background-color: #fff;
  border-radius: 5px;
  min-width: 40rem;
  width: 65%;
  max-width: 40rem;
  min-height: 50rem;
  height: 65%;
  max-height: 50rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  padding: 2rem;
  position: relative;
}
.teamsContainer ul {
  padding: 0;
  list-style: none;
}
.teamsContainer > div {
  display: flex;
  flex-direction: column;
  height: 95%;
}
.teamsContainer > div > span,
.teamsContainer > div > div > span {
  color: grey;
  font-size: 0.9rem;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 2rem;
}
.menu > li {
  width: 33%;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.menu > li > a {
  color: #1B335E;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.menu > li::after {
  content: " ";
  display: block;
  width: 60%;
  margin: auto;
  height: 2px;
  margin-top: 0.5rem;
}

.active {
  color: #244EFF;
}
.active a {
  transition: 0.2s;
  color: #244EFF !important;
}
.active::after {
  transition: 0.2s;
  background-color: #244EFF;
}

.slider {
  height: 100%;
  width: 100%;
  overflow: auto;
  /* Optional: show position indicator in red */
  display: flex;
}
.slider::-webkit-scrollbar {
  z-index: -10;
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.slider::-webkit-scrollbar-thumb {
  background: transparent;
}
.slider .col {
  min-width: 100%;
}
.slider .col:first-child {
  margin-right: 2rem;
}

.col {
  height: 100%;
  max-height: 100%;
}

.transitionRight {
  animation: swipeOutLeft;
  animation-duration: 1s;
  animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

.added {
  color: #244EFF;
  height: 1.65rem;
  display: flex;
  align-items: center;
}

@keyframes swipeOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-150%);
  }
}
.transitionRemove {
  animation: swipeOutRight;
  animation-duration: 1s;
  animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

@keyframes swipeOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(150%);
  }
}
.itemsList {
  display: flex;
  flex-direction: column;
  overflow: auto !important;
  max-height: 65%;
  height: 100%;
  margin-top: 2rem;
  width: 100%;
}
.itemsList li {
  margin: 0.5rem 0;
  margin-left: auto;
  width: 100%;
  transition: 0.2s;
  z-index: 2;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 0.85rem;
  font-weight: 600;
  position: relative;
  background: #F4F7FC;
  border-radius: 5px;
}
.itemsList li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0rem;
  top: 0;
  z-index: -1;
  bottom: 0;
  margin: auto;
  height: 4px;
  border-radius: 20px;
  width: 4px;
  background: transparent;
  transition: 0.2s;
}
.itemsList li:hover {
  width: 95%;
  cursor: pointer;
}
.itemsList li:focus-within {
  width: 95%;
}
.itemsList li:focus-within::before {
  content: "";
  display: block;
  left: -1em;
  height: 8px;
  width: 8px;
  background: #244EFF;
}
.itemsList li > div {
  display: flex;
  justify-content: space-between;
}
.itemsList li > div > span > span {
  font-weight: normal;
  font-size: 0.75rem;
  text-decoration: underline;
}

.groupName {
  color: #244EFF !important;
  border-radius: 5px;
  font-weight: normal;
  font-size: 0.75rem;
}

.infos {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.25rem;
}

.primaryTxt {
  color: #244EFF;
}

.teamAssignment {
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem 2rem;
  margin-bottom: auto;
}
.teamAssignment h2 {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  margin-top: 0;
}
.teamAssignment h2 span {
  color: #244EFF !important;
  font-size: 1.2rem !important;
}
.teamAssignment > div {
  margin-bottom: 1rem;
}
.teamAssignment .groupName {
  font-weight: 600;
}
.teamAssignment > span {
  color: grey;
  padding-top: 0.5rem;
  font-size: 0.8rem;
}

.assignItem {
  flex-direction: row !important;
  align-items: center !important;
}
.assignItem:hover, .assignItem:hover button {
  color: #244EFF;
}
.assignItem > span {
  padding-top: 0 !important;
}
.assignItem button {
  color: #1B335E;
  transition: 0.2s;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.teamAssignment {
  flex-direction: row !important;
  height: 100%;
}
.teamAssignment ul {
  padding: 0;
}
.teamAssignment .search {
  margin: 0;
}
.teamAssignment .col span {
  padding-top: 0.5rem;
  font-size: 0.8rem;
}

.users {
  overflow: initial;
}
.users li:not(.assignItem)::before {
  content: "";
  display: block;
  position: absolute;
  left: -1em;
  height: 8px;
  border-radius: 20px;
  width: 8px;
  background: #244EFF;
}

.searchInput {
  display: flex;
  position: relative;
}
.searchInput svg {
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0.75rem;
  position: absolute;
}

.search {
  text-indent: 2rem;
  border-radius: 5px;
  width: 100%;
  outline: none;
  border: 1px solid transparent;
  margin: 1rem 0;
  padding: 0.75rem 0.5rem;
  background: #F4F7FC;
  display: block;
  transition: 0.2s;
}
.search:focus {
  border-color: #244EFF;
}

.all {
  cursor: pointer;
  transition: 300ms;
  float: right;
}
.all:hover {
  color: #244EFF;
}
