.container {
  width: auto;
  position: relative;
  border: 1px solid #E7EEF8;
  border-radius: 5px;
  padding: 0.5rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.container:hover {
  border-color: #244EFF;
}
.container input {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}
.container input[type=file],
.container input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}
.container span {
  top: 0;
  line-height: 1.5rem;
  font-size: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  max-width: 80%;
  margin-left: 0.5rem;
  white-space: nowrap;
  align-items: center;
}
.container span svg {
  margin-right: 0.5rem;
}
.container svg {
  left: auto;
  right: -1.5rem;
}

.uploadedFile {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.uploadedFile span {
  top: 0;
  font-size: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.uploadedFile svg {
  right: 0rem;
  top: 0;
  bottom: 0;
  cursor: pointer;
  margin: auto;
  position: absolute;
  z-index: 0;
}
