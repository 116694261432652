@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");
@import 'assets/style/global';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: subpixel-antialiased;
    text-rendering: optimizeLegibility;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004) !important;
    box-sizing: border-box;
}

.rdtActive {
    background-color: $color-primary !important;
}

.rdtPicker {
    border-radius: 10px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
}

.rdtDay {
    border-radius: 50%;
    font-weight: 400;
    height: 30px !important;
    font-size: 0.9rem;
}

.rdtTimeToggle {
    border-radius: 10px;
}

.rdtDays th {
    font-weight: bolder;
}

.rdtToday::before {
    border-bottom-color: $color-primary !important;
}

.rdtCounter .rdtBtn {
    color: $color-primary;
}

.rdtSwitch {
    cursor: pointer !important;
}

.rdtPicker {
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1) !important;
}

li {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

li * {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    outline: none;
}

a,
a:hover,
a:visited,
a:focus {
    text-decoration: none;
}

img {
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

html {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

body {
    background: $outter-background-color;
    color: $color-darker-accent;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.primaryBg {
    background: $outter-background-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
}

/** Calendar **/

.rdrNextPrevButton {
    background: none !important;
}

.rdrMonth {
    margin: auto;
}

.rdrDateDisplay {
    display: none !important;
}

.rdrDayHovered {
    z-index: 1 !important;
}

.rdrDayToday span::after {
    content: none !important;
}

.rdrStartEdge + span {
    border: 22px solid $color-primary;
    width: 44px !important;
    height: 44px !important;
    top: -50% !important;
    left: 0 !important;
    border-radius: 50% !important;
    transform: translateY(30%) !important;
    z-index: 2 !important;
}

.rdrStartEdge + span:not(.rdrDayNumber) {
    display: none;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span {
    color: #fff !important;
    z-index: 2;
}

.rdrEndEdge {
    color: #fff !important;
    border: 1px solid $color-primary !important;
    width: 44px !important;
    height: 44px !important;
    border-radius: 50% !important;
    left: 0 !important;
    top: -10% !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview {
    display: none !important;
}

.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
    color: #000 !important;
}

.rdrEndEdge + span span {
    color: #000 !important;
}

.rdrDayStartPreview .rdrDayEndPreview {
    border-radius: 50% !important;
    border: 1px solid $color-primary !important;
}

.rdrInRange,
.rdrStartEdge {
    color: #fdf8f7 !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #000 !important;
}

::-webkit-scrollbar-track {
    background: transparent;
}
