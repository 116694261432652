.container {
  width: 100%;
  min-width: 35vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 5px;
  position: relative;
  padding: 2rem;
}
.container h2 {
  width: 100%;
  display: flex;
  margin-top: 0;
  color: #1B335E;
}
.container h2 span {
  margin-left: 0.5rem;
  color: #5c74b2;
}
.container h2 svg {
  margin-left: auto;
}
.container label {
  font-size: 0.7rem;
  font-weight: 600;
  color: #5c74b2;
  margin-left: 0.15rem;
}
.container input {
  border: 1px solid #F4F7FC;
  padding: 1rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  margin-bottom: 1rem;
  outline: none;
}
.container input:focus {
  border-color: #244EFF;
  box-shadow: 0px 12px 40px rgba(230, 208, 201, 0.28);
}
.container .datePick ~ input {
  margin-bottom: 1.5rem;
}
.container button {
  margin: 1.5rem auto 0 0;
}

.primaryColor {
  color: #244EFF;
}

.datePick {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.datePick > div {
  width: 48%;
}
.datePick > div input {
  transition: border-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  font-size: 0.8rem;
  width: 100%;
}
.datePick > div input:hover, .datePick > div input:focus-within {
  border-color: #244EFF;
  box-shadow: 0 12px 40px rgba(230, 208, 201, 0.28);
}

.row {
  display: flex;
  justify-content: space-between;
}

.currentEventPreview {
  width: 100%;
}
.currentEventPreview img {
  margin: 0 0 1rem 0;
  max-width: 100%;
  max-height: 30vh;
}
.currentEventPreview + div {
  width: 48%;
}

.uploadContainer {
  display: flex;
  width: 100% !important;
}
.uploadContainer > div {
  width: 100%;
}

.btnsContainer {
  display: flex;
}
.btnsContainer button {
  margin: 2rem 0 0;
}
.btnsContainer button:last-child {
  margin-left: 1rem;
}

.eventName {
  margin-top: 1rem;
  width: 100%;
}
