.btn {
  background-color: transparent;
  display: flex;
  border: 1px solid;
  text-align: center;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  justify-content: center;
  align-items: center;
  margin: 0.5rem auto;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 5px;
}
.btn * {
  color: inherit;
}

.link-primary {
  border-color: transparent;
}
.link-primary:hover {
  color: #244EFF;
  cursor: pointer;
}

.whiteBg {
  background-color: #fff;
}

.primary {
  color: #244EFF;
  border-color: #244EFF;
  cursor: pointer;
}
.primary:hover {
  background-color: #244EFF;
  color: #fff;
}
.primary:hover svg {
  stroke: #fff;
}
.primary svg {
  transition: 0.2s;
  margin-left: 0.5rem;
  stroke: #244EFF;
  stroke-width: 1px;
}

.left {
  margin-left: 0 !important;
  margin-right: 0.5rem;
}

.primaryFill {
  color: #fff;
  background-color: #244EFF;
  border-color: #244EFF;
}
.primaryFill:hover {
  background-color: transparent;
  color: #244EFF;
  cursor: pointer;
}
.primaryFill:hover svg {
  stroke: #244EFF;
}
.primaryFill svg {
  transition: 0.2s;
  margin-left: 0.5rem;
  stroke: #fff;
  stroke-width: 1px;
}

.secondary {
  color: #1B335E;
  border-color: #1B335E;
}
.secondary:hover {
  cursor: pointer;
  background: #1B335E;
  color: #fff;
}
.secondary:hover svg {
  stroke: #fff;
}
.secondary svg {
  transition: 0.2s;
  margin-left: 0.5rem;
  stroke: #1B335E;
  stroke-width: 1px;
}

.secondaryFill {
  color: #fff;
  background-color: #1B335E;
  border-color: #1B335E;
}
.secondaryFill:hover {
  background-color: transparent;
  color: #1B335E;
  cursor: pointer;
}

.white {
  border-color: transparent;
  background-color: #fff;
  cursor: pointer;
}
.white:hover {
  color: #fff;
  background-color: #1B335E;
}

.disabled, .loading {
  pointer-events: none;
}
.disabled .loadingCircle, .loading .loadingCircle {
  animation: spin infinite 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hidden {
  opacity: 0;
}
