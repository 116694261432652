@import "../../assets/style/global";

.container {
    width: 80%;
    height: 90vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    textarea {
        min-width: 45rem;
        min-height: 25rem;
    }
    .editContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 100%;
        gap: 0 1rem;
        > h1 {
            flex: 100%;
        }
        > ul {
            list-style: none;
            padding: 0;
            max-height: 100%;
            overflow: auto;
            > li {
                display: flex;
                gap: 0.5rem;
                > button {
                    background-color: transparent;
                    cursor: pointer;
                    border-radius: $hugeRadius;
                    height: 2rem;
                    width: 2rem;
                    font-size: 1rem;
                    line-height: 1rem;
                    display: flex;
                    margin: auto 0;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
