:local :root {
  --color-primary: #ff7954;
  --color-secondary: #ffc300;
  --color-dark-accent: #402927;
  --color-darker-accent: #66433e;
  --color-lighter-accent: #f7f5f5;
  --color-light-accent: #f0e9e9;
  --color-light-accent-variation: #ebe4e1;
  --color-light-accent-variation1: #ECEFF6;
  --color-light-accent-variation2: #f8f2f0;
}

.statisticContainer {
  position: relative;
  width: 60%;
  height: 25rem;
  min-height: 25rem;
  background: rgba(255, 255, 255, 0.4);
  padding: 0.25rem 1rem;
  border-radius: 5px;
}

.entitySelectContainer,
.loadingContainer {
  position: absolute;
  width: 35%;
  right: 0;
  top: 7.5rem;
}

.entitySelectContainer > form > ul {
  width: 25%;
  position: fixed;
  padding: 1rem;
  list-style: none;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadingContainer {
  left: 1rem;
  margin-top: 3rem;
  color: #244EFF;
}
.loadingContainer > svg {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.singleStatContainer {
  padding-top: 2rem;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 20rem;
  gap: 2rem;
}

.chartContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 90%;
  height: 75%;
}
.chartContainer .loadingContainer {
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
}

.entitiesContainer {
  position: absolute;
  bottom: -10rem;
  background: rgb(255, 255, 255);
  border-radius: 5px;
}
.entitiesContainer > div {
  width: 25%;
  padding: 0.25rem;
  position: fixed;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  font-size: 0.9rem;
}
.entitiesContainer > div > input {
  width: 100%;
  margin-top: 0;
}
.entitiesContainer > div > ul {
  padding: 0.25rem 1rem;
  list-style: none;
  max-height: 35rem;
  overflow: auto;
  line-height: 1.75rem;
}
