.passwordContainer {
  position: relative;
  display: flex;
}
.passwordContainer > div {
  display: flex;
  align-items: center;
  position: absolute;
  outline: none;
  border: none;
  background: none;
  right: 1rem;
  cursor: pointer;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0;
}

.error {
  bottom: 0;
  height: 1rem;
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  margin: auto;
}
.success h2 {
  color: #244EFF;
}
.success p {
  margin-bottom: 0;
}
