.loading {
  color: #244EFF;
  animation: spin infinite 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hidden {
  opacity: 0;
}

.container {
  animation: fadeIn 1000ms linear forwards;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* Optional: show position indicator in red */
}
.container::-webkit-scrollbar {
  z-index: -10;
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.container::-webkit-scrollbar-thumb {
  background: transparent;
}

.primaryColor {
  color: #244EFF;
}

.container > div {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
}
.container > div h1 {
  font-size: 2.25rem;
  max-height: 1.6rem;
  margin-right: auto;
}

.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 5px;
  width: 25%;
  height: 13rem;
  padding: 0 1rem 1rem;
  z-index: 8;
  inset: 0;
  margin: auto;
  left: 15%;
  text-align: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.288);
  backdrop-filter: blur(10px);
  margin: auto;
}
.modal .slidesContainer {
  position: relative;
  width: 200%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.modal .slide {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal h4 {
  line-height: 1rem;
}
.modal .slide > div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}
.modal .slide > div button {
  margin: 1.5rem 0.5rem 0 !important;
}
.modal .primaryTxt {
  color: #244EFF;
}

.signaturePreview {
  pointer-events: none;
}

.btnTemplate {
  cursor: pointer;
  transition: 0.2s;
  margin-bottom: 1rem;
  display: flex;
  margin-left: auto;
  justify-content: space-evenly;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  color: #1B335E;
  background: none;
}
.btnTemplate:hover {
  color: #244EFF;
}
.btnTemplate svg {
  font-size: 1.2rem;
  margin-left: 0.65rem;
}

.row {
  height: 100%;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.options {
  position: relative;
  min-height: 65vh;
  max-height: 65vh;
  overflow: auto;
  background: #fff;
  border-radius: 5px;
  width: 40%;
  min-width: 15rem;
  padding-top: 5.5rem;
}

.tabsContainer {
  width: 100%;
  margin: auto;
  margin-top: 1.5rem;
  display: flex;
  left: 0;
  right: 0;
  margin: auto;
  top: 2rem;
  padding-bottom: 1rem;
  position: absolute;
  justify-content: center;
  align-items: center;
}
.tabsContainer > button {
  margin-right: -15%;
  margin-left: 5%;
  border: none;
  outline: none;
  background: none;
  color: #1B335E;
  cursor: pointer;
}
.tabsContainer > button:hover {
  color: #244EFF;
}
.tabsContainer .switchTabs {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 40%;
  height: 3rem;
}
.tabsContainer .switchTabs * {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  outline: none;
}
.tabsContainer input:not(:checked) + .sliderTabs:before {
  transform: translateX(0);
}
.tabsContainer input:checked + .sliderTabs:before {
  transform: translateX(100%);
}
.tabsContainer .switchTabs input {
  outline: none;
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}
.tabsContainer .tabTitles {
  width: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 6%;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  justify-content: space-between;
}
.tabsContainer .tabTitles span {
  width: 50%;
  line-height: 4.5rem;
  color: #0F1E4C;
  font-size: 0.85rem;
  font-weight: bold;
}
.tabsContainer .sliderTabs {
  height: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;
  background-color: #F3F7FB;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-left: 12px solid #F3F7FB;
  border-right: 12px solid #F3F7FB;
}
.tabsContainer .sliderTabs:before {
  position: absolute;
  content: "";
  height: 65%;
  width: 50%;
  bottom: 0;
  left: 0;
  top: 0;
  margin: auto;
  margin-left: 0;
  margin-right: auto;
  background-color: white;
  transition: 0.4s;
}
.tabsContainer .sliderTabs {
  border-radius: 10px;
}
.tabsContainer .sliderTabs:before {
  border-radius: 10px;
}

.col {
  display: flex;
  flex-direction: column;
  width: 55%;
}
.col .CTAsContainer {
  width: 98%;
  margin: auto;
  bottom: -4rem;
  left: 0;
  right: 0;
  display: flex;
  position: absolute;
  justify-content: space-between;
}
.col .CTAsContainer > div {
  display: flex;
}
.col .CTAsContainer > div button:first-child {
  margin-right: 1rem;
}
.col .CTAsContainer button {
  margin: 0;
}
.col .hide {
  opacity: 0;
  pointer-events: none;
}
.col .blinking {
  animation: blink 1s infinite;
}
.col button:hover svg {
  stroke: #fff !important;
}

.confirm {
  width: 15rem;
  min-width: 15rem;
  transition: min-width 1s !important;
  display: flex;
  align-items: center;
}
.confirm span {
  margin-right: 1rem;
}
.confirm:hover {
  background-color: #fff !important;
  cursor: default !important;
}
.confirm:hover button {
  cursor: pointer;
}
.confirm .noBgBtn {
  background: #1B335E;
  padding: 0.15rem 0.75rem !important;
  border: #1B335E;
  border-radius: 5px;
  color: #fff;
  margin-left: 1rem;
}
.confirm button {
  border: none;
  background: none;
  color: #244EFF;
  padding: 0;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  margin: 0 0 0 0.25rem;
}
.confirm button:last-child {
  color: #1B335E;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.previewContainer {
  overflow: auto;
  max-height: 100%;
}

.signatureContainer {
  display: flex;
  width: 100%;
  position: relative;
  padding: 0.25rem 1rem 1rem;
  flex-direction: column;
  background-color: #fff;
  height: fit-content;
  max-height: 80%;
  border-radius: 5px;
}
.signatureContainer .preview {
  pointer-events: none;
}
.signatureContainer .browserHeader {
  width: 100%;
  height: 1.75rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.signatureContainer .browserHeader .btnsContainer {
  display: flex;
  justify-content: space-between;
  width: 2.85rem;
  list-style: none;
  padding: 0;
}
.signatureContainer .browserHeader .btnsContainer > li {
  min-width: 10px;
  min-height: 10px;
  border-radius: 20px;
}
.signatureContainer .browserHeader .btnsContainer .close {
  background: #ff5d24;
}
.signatureContainer .browserHeader .btnsContainer .reduce {
  background: #ffbd44;
}
.signatureContainer .browserHeader .btnsContainer .fullscreen {
  background: #00ca4e;
}
.signatureContainer .browserHeader input {
  margin-left: 1rem;
  width: 100%;
  text-indent: 0.5rem;
  height: 1.5rem;
  font-size: 0.7rem;
  color: grey;
  border-radius: 5px;
  border: none;
}
.signatureContainer .lazyLoadingLong,
.signatureContainer .lazyLoadingShort,
.signatureContainer .lazyLoadingMedium {
  border-radius: 5px;
  background: #f1f1f1;
  width: 100%;
  height: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: auto;
}
.signatureContainer .lazyLoadingShort {
  width: 40%;
}
.signatureContainer .lazyLoadingMedium {
  width: 70%;
}
.signatureContainer p {
  font-size: 0.8rem;
}

/** Tab 2 **/
.tabContent {
  display: flex;
  position: relative;
  width: 95%;
  height: 100%;
  overflow: auto;
  margin: auto;
  flex-direction: column;
  align-items: center;
  color: #1B335E;
  text-align: center;
  padding: 0;
}
.tabContent > div {
  padding: 5%;
}
.tabContent textarea {
  width: 100%;
  resize: vertical;
}
.tabContent hr {
  height: 1px;
  background: none;
  width: 100%;
  border: none;
  border-top: 1px solid rgba(101, 68, 62, 0.14);
  margin: 2rem 0;
}
.tabContent .span {
  font-size: 0.9rem;
  text-align: left;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.uploadedFile {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.uploadedFile svg {
  right: 1rem;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  z-index: 3;
}

.rangeUpload {
  flex: 100%;
  background: #fff;
  width: 100%;
  border-radius: 5px;
}

.placeholderImg,
.placeholderBanner {
  background: rgba(184, 184, 184, 0.596);
  border-radius: 5px;
  margin-right: 40%;
  height: 38px;
  flex: 100%;
  margin-bottom: 1rem;
}

.placeholderBanner {
  margin-top: 1rem;
  margin-bottom: 0;
  margin-right: 0;
}

.input {
  background: none;
  border: none;
  outline: none;
}

.optionContainer {
  margin: auto;
}

.switch {
  transform: scale(0.6) translateX(30%);
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch * {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  outline: none;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #244EFF;
}

input:focus + .slider {
  box-shadow: 0 0 1px #244EFF;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 20px;
}

.iconsContainer {
  margin-top: 0.5rem;
  width: 100%;
  max-width: 100%;
  background: #fff;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconsContainer .borderInput {
  transition: 0.2s;
  background: #fff;
  border: 1px solid #F4F7FC;
  padding: 1rem;
  padding-right: 5rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  font-family: "Montserrat";
  outline: none;
}
.iconsContainer label:first-of-type {
  font-size: 0.85rem;
}
.iconsContainer label {
  margin: 0;
  color: #000 !important;
}

.topBannerContainer {
  flex-wrap: nowrap;
}
.topBannerContainer .fileUpload {
  width: auto;
  max-width: 60%;
  max-height: 1rem;
}
.topBannerContainer .fileUpload input {
  top: 0;
}
.topBannerContainer .fileUpload span {
  font-size: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.topBannerContainer .fileUpload svg {
  left: auto;
  right: -1.5rem;
}

.reorganizeSocials {
  display: flex;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.reorganizeSocials div {
  cursor: move;
}

.primaryTxt {
  color: #244EFF;
}
