.container {
  display: flex;
  flex-wrap: wrap;
  height: 70vh;
  color: #1B335E;
}
.container h1 {
  width: 100%;
}

.primaryColor {
  color: #244EFF;
}

.infosContainer {
  background-color: #fff;
}

.bannerPreview {
  height: auto;
  width: 40%;
  padding: 0.5rem;
  margin: auto;
}

.eventsContainer {
  background-color: #fff;
  border-radius: 5px;
  min-width: 40rem;
  width: 65%;
  max-width: 40rem;
  padding: 2rem;
  position: relative;
  min-height: 50rem;
  height: 65%;
  max-height: 50rem;
  margin-right: 2rem;
}
.eventsContainer ul {
  padding: 0;
  list-style: none;
}
.eventsContainer > div {
  display: flex;
  flex-direction: column;
  height: 95%;
}
.eventsContainer > div > span {
  color: grey;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.actionsContainer {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  margin: auto;
}
.actionsContainer:hover {
  color: #244EFF;
}
.actionsContainer svg {
  opacity: 0.6;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 2rem;
}
.menu li {
  width: 33%;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.menu li::after {
  content: " ";
  display: block;
  width: 60%;
  height: 2px;
  margin: 0.5rem auto auto;
}

.active {
  transition: 0.2s;
  margin-bottom: 0.5rem;
  color: #244EFF;
}
.active::after {
  transition: 0.2s;
  background-color: #244EFF;
}

.pastEvent {
  filter: saturate(0);
}

.eventText {
  display: flex;
  margin: auto;
  position: relative;
  margin-left: 1rem;
}

.duration {
  margin-top: 0.25rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: normal;
}
.duration .col {
  display: flex;
  flex-direction: column;
}
.duration .col:first-child {
  margin-right: 1rem;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  padding: 0 1rem 1rem;
  z-index: 3;
  left: 15%;
  overflow: hidden;
  text-align: center;
  right: 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.288);
  backdrop-filter: blur(10px);
  margin: auto;
}
.modal h4 {
  line-height: 2rem;
}
.modal > div {
  display: flex;
  width: 70%;
}

.bold > span {
  font-weight: bold;
}

.boldPrimary {
  font-weight: bold;
  color: #5c74b2 !important;
}

.itemsList {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  margin-top: 1rem;
  z-index: 0;
}
.itemsList li {
  margin: 0.5rem 0 0.5rem auto;
  width: 100%;
  transition: 0.2s;
  z-index: 2;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 600;
  position: relative;
  background: #F4F7FC;
  border-radius: 5px;
}
.itemsList li .checkbox {
  width: 100%;
  height: 100%;
  top: 0;
  border: none;
  background: none;
  opacity: 0;
  cursor: pointer;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
}
.itemsList li .checkbox:checked ~ span::before {
  content: "";
  position: absolute;
  display: block;
  z-index: -1;
  left: -1rem;
  top: 0;
  z-index: -1;
  bottom: 0;
  margin: auto;
  height: 8px;
  border-radius: 20px;
  width: 8px;
  background: #244EFF;
  transition: 0.2s;
}
.itemsList li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  bottom: 0;
  margin: auto;
  height: 6px;
  border-radius: 20px;
  width: 6px;
  background: transparent;
  transition: 0.2s;
}
.itemsList li > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
}
.itemsList li > div > span > span {
  font-weight: normal;
  font-size: 0.75rem;
}
.itemsList li > div img {
  height: auto;
}

.selected,
.preview {
  width: 95% !important;
}

.selected::before {
  content: "";
  display: block !important;
  left: -1rem !important;
  height: 8px !important;
  width: 8px !important;
  background: #244EFF !important;
}

.infos {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.25rem;
}

.searchInput {
  display: flex;
  position: relative;
}
.searchInput svg {
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0.75rem;
  position: absolute;
}

.search {
  text-indent: 2rem;
  border-radius: 5px;
  width: 100%;
  outline: none;
  border: 1px solid transparent;
  margin: 1rem 0;
  padding: 0.75rem 0.5rem;
  background: #F4F7FC;
  display: block;
  transition: 0.2s;
}
.search:focus {
  border-color: #244EFF;
}

.primaryTxt {
  color: #5c74b2;
}

.createEventContainer {
  max-width: 45%;
}

.eventPreview {
  margin-bottom: auto;
  width: 100%;
  padding: 2rem;
}
.eventPreview img {
  max-width: 25rem;
}
.eventPreview svg {
  margin-left: auto;
}
.eventPreview h2 {
  display: flex;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  margin-top: 0;
}
.eventPreview .groupName {
  font-weight: 600;
}

.flipcontainer {
  perspective: 1000px;
  max-width: 50%;
}

.flip {
  transform: rotateY(180deg);
}

.flipcontainer,
.front,
.back {
  width: 100%;
  height: 100%;
}

/* flip speed goes here */
.flipper {
  transition: 0.6s linear;
  transform-style: preserve-3d;
  height: 60%;
  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  width: 100%;
  backface-visibility: hidden;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.front h2,
.back h2 {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  margin-top: 0;
}
.front h2 svg:hover,
.back h2 svg:hover {
  cursor: pointer;
  color: #244EFF;
}
.front > div,
.back > div {
  margin-bottom: 1rem;
}
.front .groupName,
.back .groupName {
  font-weight: 600;
}
.front > span,
.back > span {
  color: grey;
  padding-top: 0.5rem;
  font-size: 0.8rem;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
}
.back > div {
  width: 100%;
  max-width: 100%;
}
