.container {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.container > h5 {
  text-transform: uppercase;
  text-align: left;
  margin: 0;
}
.container > h5 .primaryTxt {
  color: #5c74b2;
}
.container > p {
  font-size: 1rem;
}

.swiperContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

.disabled {
  opacity: 0.3;
}

.enabled {
  opacity: 1;
}

.active {
  opacity: 1;
  color: #5c74b2;
}

.menuLinks {
  padding: 0;
  width: calc(25% - 2rem);
  height: 7.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
}
.menuLinks li {
  display: flex;
  font-weight: 600;
  font-size: 0.9rem;
}
.menuLinks li svg {
  margin-left: 0.5rem;
}
.menuLinks li:hover {
  opacity: 1;
  color: #5c74b2;
  cursor: pointer;
}

.swiper {
  position: relative;
  max-height: 10rem;
  height: 10rem;
}

.btnsContainer {
  position: absolute;
  bottom: 0;
  left: 2rem;
  display: flex;
  margin-top: auto;
  gap: 1rem;
  margin-right: auto;
}
.btnsContainer :first-child {
  margin-left: 0;
}

.content {
  font-size: 0.9rem;
  max-height: 12rem;
  padding: 1.5rem calc(25% - 2rem) 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
}
.content > button {
  margin-left: 0;
  position: absolute;
  bottom: 0;
  left: 2rem;
  display: flex;
  margin-top: auto;
}
