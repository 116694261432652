.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.container li {
  width: 100%;
  position: relative;
}
.container input[type=checkbox],
.container input[type=radio] {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  cursor: pointer;
}

.open input {
  border-radius: 5px 5px 0 0;
}
.open .inputContainer > svg {
  transform: rotate(-180deg);
}

.inputContainer {
  position: relative;
  display: flex;
  width: 100%;
}
.inputContainer input[type=text] {
  text-overflow: ellipsis;
  z-index: 6;
  width: 100%;
  font-weight: bold;
  color: #1B335E;
  padding: 0.65rem 2rem 0.65rem 1rem;
}
.inputContainer svg {
  transition: 0.2s;
  cursor: pointer;
  z-index: 7;
  right: 1rem;
  bottom: 0;
  top: 0;
  margin: auto;
  position: absolute;
  color: #1B335E;
}

.splitter {
  font-size: 0.8rem;
  color: #244EFF;
  font-weight: bold;
}

.list {
  position: absolute;
  top: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  left: 0;
  right: 0;
  z-index: 5;
  margin: 0 auto;
  border: 1px solid #DDE5F1;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 20px 30px #DDE5F1;
  border-radius: 0 0 5px 5px;
  padding: 2.65rem 0 0;
  list-style: none;
  opacity: 0;
  max-height: inherit !important;
  animation: dropDown 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.list li {
  margin: 0;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: normal;
  color: #1B335E;
}
.list li input,
.list li input + svg {
  opacity: 0;
  color: #244EFF;
}
.list li input:checked + svg {
  opacity: 1;
}
.list li:hover, .list li:focus-within {
  transition: 0.2s;
  color: #1B335E;
  background-color: #ECEFF6;
}

@keyframes dropDown {
  0% {
    opacity: 0;
    max-height: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    max-height: 100vh;
  }
}
