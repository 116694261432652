.container {
  width: 90%;
  min-height: 80vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dimensions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dimensions > div {
  display: flex;
  width: 100%;
  max-width: 48%;
  flex-direction: column;
}
.dimensions input {
  width: 100%;
}

.checkboxContainer {
  display: flex;
  padding-top: 2rem;
  align-items: center;
}
.checkboxContainer > label {
  font-weight: bold;
  text-transform: uppercase;
  color: #1B335E;
  cursor: pointer;
}

.inputsContainer {
  padding-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.inputsContainer > .dimensions label {
  color: #244EFF;
  font-weight: 400;
  font-size: 0.8rem;
}
.inputsContainer input,
.inputsContainer textarea {
  resize: none;
  box-shadow: 0 2px 10px rgba(230, 208, 201, 0.8);
  transition: 0.2s;
  background: #fff;
  border: 1px solid transparent;
  padding: 1rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  font-family: "Montserrat", serif;
  outline: none;
}
.inputsContainer input:focus,
.inputsContainer textarea:focus {
  border: 1px solid #244EFF;
  box-shadow: 0 12px 40px rgba(230, 208, 201, 0.28);
}

.inputContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  position: relative;
  height: 10rem;
}

.imgPreview {
  max-height: 100%;
  padding-right: 1rem;
}

.message {
  opacity: 1;
  animation: fade 5s linear forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  text-align: center;
  border-radius: 40px;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(27, 51, 94, 0.4);
  background-color: #244EFF;
  color: #fff;
  font-weight: bold;
  height: 3.5rem;
  top: 5rem;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes fade {
  0%, 100% {
    opacity: 0;
  }
  10%, 90% {
    opacity: 1;
  }
}
.fileUpload {
  display: flex;
  box-shadow: 0 2px 10px rgba(230, 208, 201, 0.8);
  height: 10rem;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border-radius: 5px;
}
.fileUpload .rangeUpload {
  display: flex;
  flex-direction: column;
}
.fileUpload input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 2;
}
.fileUpload span {
  margin: auto;
  font-size: 0.9rem;
  line-height: 3rem;
}
.fileUpload span svg {
  vertical-align: sub;
  margin-right: 0.5rem;
  margin-bottom: 2px;
}

.uploadedFile {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.uploadedFile svg {
  right: 1rem;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  z-index: 3;
}

.logo {
  opacity: 0.5;
  position: fixed;
  bottom: 0;
  height: 5rem;
}
