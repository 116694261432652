@import "../../assets/style/global";

.desktop {
    animation: fadeIn 0.5s forwards;
    height: 100vh;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    .desktopSubcontainer {
        opacity: 0;
        animation: fadeIn 0.5s forwards;
        width: 100%;
        height: 100%;
        position: relative;
        margin: auto;
        border-radius: $mediumBorderRadius;
        display: flex;
        flex-direction: column;
        > div {
            flex: 1;
        }
        background-color: $outter-background-color;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.mainContent {
    max-height: 100vh;
    overflow: hidden;
    height: 100%;
    margin: 0 2rem 3rem;
    display: flex;
    > ul {
        padding: 5rem;
    }
    h5 {
        font-size: 1.2rem;
        margin: 0;
    }
}

.menuContainer {
    display: flex;
    opacity: 0;
    animation: fadeIn 1s 0.5s forwards;
    margin: 0 3rem 0 auto;
    max-height: 100vh;
    min-height: 80vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.userInfos {
    width: 100%;
    font-size: 0.9rem;
    text-align: center;
    color: $color-dark-accent;
    p {
        line-height: 0.5rem;
        margin-bottom: 0;
    }
    .capitalize {
        font-weight: bold;
        line-height: 1rem;
        text-transform: uppercase;
    }
    > img {
        max-width: 100px;
        max-height: 100px;
        width: auto;
        height: auto;
        margin-bottom: 3rem;
    }
}

.dashboardContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: 100vh;
    overflow: auto;
    height: 100%;
    padding: 2rem;
    padding-top: 1rem;
    background-color: $background-color;
    position: relative;
    border-radius: $borderRadius;
    align-content: flex-start;
    opacity: 0;
    animation: fadeIn 1s 1s forwards;
    h1 {
        flex: 100%;
        color: $color-dark-accent;
    }
    .tilesContainer {
        width: 26vw;
    }
    > div {
        width: 100%;
        max-height: 100%;
    }
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    & svg {
        animation: rotate 1s infinite ease;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-360deg);
    }
}
