.footer {
  opacity: 0.5;
  position: absolute;
  margin: auto;
  bottom: 1rem;
  right: 3rem;
  padding: 0 !important;
  gap: 0.5rem;
  list-style: none;
  display: flex;
  align-items: center;
}
.footer a {
  color: #5c74b2;
  font-size: 0.8rem;
}
