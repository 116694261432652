@media screen and (max-width: 680px) {
  .dashboardContainer {
    flex-direction: row;
  }
}
.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.row:not(.load) {
  animation: fadeIn 500ms forwards ease-in;
}

.col {
  display: flex;
  flex-direction: column;
  max-width: 45%;
}
.col:first-child {
  margin-right: 2rem;
  max-width: 65%;
}
.col > div:last-child {
  margin-top: -0.5rem;
}

.signaturePreview,
.eventPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.signaturePreview > div,
.eventPreview > div {
  transform: scale(0.8);
  transform-origin: left;
  margin-right: -5rem;
}
.signaturePreview a,
.eventPreview a {
  color: #ff7954;
}
.signaturePreview .banner,
.eventPreview .banner {
  width: 100%;
  height: auto;
}

.eventPreview {
  min-height: 12rem;
  justify-content: space-between;
}

.signatureContainer {
  align-items: center;
  margin-bottom: 2rem;
  transition: width 1s;
  display: flex;
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem 1.5rem;
  flex-wrap: nowrap;
  overflow: hidden;
}

.h1 {
  color: #efeef4;
  flex: 100%;
  font-size: 1.75rem;
}

.spacer {
  max-width: 50%;
  min-width: 45%;
}

.open {
  transition: 1s;
  max-width: 35rem;
  width: 100%;
}

.closed {
  transition: 1s;
  max-width: 25rem;
  width: 25rem;
}

.eventText {
  display: flex;
  height: auto !important;
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 3rem;
  padding: 2rem;
  background: #fff;
  border-radius: 10px;
}
.eventText > div {
  transform: scale(0.9);
  transform-origin: left;
  display: flex;
  flex-direction: column;
  position: relative;
  font-weight: 600;
  margin-right: -2rem;
  font-size: 0.9rem;
}
.eventText > div h5 {
  margin-bottom: 1rem;
}
.eventText > div::before {
  order: 3;
  content: "";
  width: 100%;
}
.eventText > div img {
  max-width: 350px;
}

.chartContainer {
  height: 200px;
  margin-right: 2rem;
}

.duration {
  margin-top: 0.25rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9rem;
  margin: 0;
  align-items: center;
  font-weight: normal;
}
.duration .col {
  display: flex;
  flex-direction: column;
}
.duration .col:first-child {
  margin-right: 1rem;
}

.title svg {
  font-size: 1rem;
  color: #244EFF;
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.load {
  opacity: 0;
}

.bold > span {
  font-weight: bold;
}
