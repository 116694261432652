@import "../../assets/style/global";

.container {
    display: flex;
    height: 80vh;
    overflow: hidden;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    h1 {
        width: 100%;
    }
}

.primaryColor {
    color: $color-primary;
}

.modalContainer {
    display: flex;
    margin: auto;
    width: 100%;
    height: 80%;
    position: absolute;
    > div {
        top: 0;
        height: fit-content;
        gap: 2rem;
        width: fit-content;
        inset: 0;
        span > span {
            color: $color-primary;
        }
    }
}

.row {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
}

.infosContainer {
    background-color: #fff;
}

.teamsContainer {
    width: $leftColMinWidth;
    max-width: $leftColMinWidth;
    min-width: $leftColMinWidth;
    background-color: #fff;
    border-radius: $borderRadius;
    padding: 2rem;
    position: relative;
    min-height: $leftColMinHeight;
    height: $leftColHeight;
    max-height: $leftColMaxHeight;
    ul {
        padding: 0;
        list-style: none;
    }
    > div {
        display: flex;
        flex-direction: column;
        height: 95%;
        > span {
            color: $color-primary-variant;
            font-weight: bold;
            margin-top: 1rem;
            font-size: 0.9rem;
        }
    }
}

.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 auto 1rem;

    li {
        width: 50%;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
    }

    li::after {
        content: " ";
        display: block;
        width: 60%;
        height: 2px;
        margin: 0.5rem auto auto;
    }
}

.noStudio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .tagline {
        max-width: 20rem;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        font-size: 1rem;
        line-height: 1.25rem;
    }
}

.active {
    transition: 0.2s;
    color: $color-primary;
    &::after {
        transition: 0.2s;
        background-color: $color-primary;
    }
}

.itemsList {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    margin-top: 1rem;
    width: 100%;
    span {
        height: 100%;
        width: 100%;
    }
    li {
        margin: 0.5rem 0 0.5rem auto;
        width: 100%;
        transition: 0.2s;
        z-index: 2;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 0.85rem;
        font-weight: 600;
        cursor: pointer;
        position: relative;
        background: $color-lighter-accent;
        border-radius: $smallBorderRadius;
        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            bottom: 0;
            margin: auto;
            height: 8px;
            border-radius: $hugeRadius;
            width: 8px;
            background: transparent;
            transition: 0.2s;
        }
        &:focus-within {
            width: 95%;
            &::before {
                content: "";
                display: block;
                left: -1em;
                height: 8px;
                width: 8px;
                background: $color-primary;
            }
        }
        > div {
            display: flex;
            justify-content: space-between;
            > span > span {
                font-weight: normal;
                font-size: 0.75rem;
                text-decoration: underline;
            }
        }
    }
}

.selected {
    width: 95% !important;
}

.groupName {
    color: $color-primary;
    border-radius: $borderRadius;
    font-weight: normal;
    font-size: 0.75rem;
}

.infos {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.25rem;
}

.searchInput {
    display: flex;
    position: relative;
    svg {
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0.75rem;
        position: absolute;
    }
}

.search {
    text-indent: 2rem;
    border-radius: $borderRadius;
    width: 100%;
    outline: none;
    border: 1px solid transparent;
    margin: 1rem 0;
    padding: 0.5rem;
    background: $color-lighter-accent;
    display: block;
    transition: 0.2s;
    &:focus {
        border-color: $color-primary;
    }
}

.primaryTxt {
    color: $color-primary;
}

.signaturePreview {
    margin-left: 3rem;
    background-color: #fff;
    border-radius: $borderRadius;
    // max-width: 30rem;
    padding: 0 2rem;
    margin-bottom: auto;
    h2 {
        font-size: 1.2rem;
        margin-bottom: 0;
        margin-top: 0;
    }
    h5 {
        font-size: 1.2rem !important;
        padding-bottom: 2rem;
    }
    > ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        list-style: none;
        height: 100%;
        padding: 0;
        overflow: auto;
        margin-bottom: 2rem;
    }
    > p {
        margin-bottom: 0;
    }
    > li {
        min-width: 20rem;
        margin: 0.5rem;
    }
    > .groupName {
        font-weight: 600;
    }
}

.btn,
.CTA {
    width: 60%;
    text-align: center;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    border: 1px solid $color-primary;
    background-color: #fff !important;
    color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem auto;
    padding: 0.75rem;
    font-size: 0.85rem;
    font-weight: 600;
    border-radius: $borderRadius;
    svg {
        transition: 0.2s;
        margin-left: 0.5rem;
        stroke: $color-primary;
        stroke-width: 1px;
    }
    &:hover {
        background-color: $color-primary !important;
        border: 1px solid transparent;
        color: #fff;
        svg {
            stroke: #fff;
        }
        cursor: pointer;
    }
}

.CTA {
    width: 15rem;
    margin: 0 5rem;
}

.actionsContainer {
    position: absolute;
    right: 1rem;
    z-index: 2;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    svg {
        color: $color-dark-accent;
        opacity: 0.6;
    }
    svg:first-child {
        font-size: 1rem;
    }
    svg:hover {
        color: $color-primary;
        opacity: 1;
    }
}
